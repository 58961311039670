import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { formatMilitaryTime, formatStandardTime } from 'utils';

import { capitalCase } from 'change-case';
import { snakeCase } from 'change-case/keys';
import { CloseIcon, styles } from 'components';
import styled from 'styled-components';

import { StyledButton, StyledLabel } from '../RecipientIntake';

export const WeeklyScheduleIntake = ({ setMissingInputs, setMatchParams, matchParams }) => {
  const weekdays = useMemo(() => {
    return dayjs.weekdays();
  }, []);

  const startDateIndex = useMemo(() => {
    const startDay = matchParams.scheduleStartDate;
    return startDay ? dayjs(startDay, 'YYYY-MM-DD').day() : 0;
  }, [matchParams]);

  const defaultDuration = useMemo(
    () => ({
      startDay: weekdays[startDateIndex],
      startDayIndex: startDateIndex,
      startTime: null,
      endDay: weekdays[startDateIndex],
      endDayIndex: startDateIndex,
      endTime: null,
    }),
    [weekdays, startDateIndex],
  );

  const durationDelimiters = useMemo(() => {
    return [
      {
        label: 'Starts on',
        dayKey: 'startDay',
        timeKey: 'startTime',
      },
      { label: 'Ends on', dayKey: 'endDay', timeKey: 'endTime' },
    ];
  }, []);

  const [selectedDayIndex, setSelectedDayIndex] = useState(startDateIndex);
  const [scheduleList, setScheduleList] = useState([]);
  const [shift, setShift] = useState(defaultDuration);
  const [recurringShiftIndexList, setRecurringShiftIndexList] = useState([startDateIndex]);
  const [error, setError] = useState(null);
  const [isRecurring, toggleIsRecurring] = useState(false);

  const resetDefaults = useCallback(() => {
    setError(null);
    setSelectedDayIndex(startDateIndex);
    toggleIsRecurring(false);
    setRecurringShiftIndexList([startDateIndex]);
    setShift(defaultDuration);
  }, [defaultDuration, startDateIndex]);

  const updateSchedule = useCallback(
    (value, key) => {
      setError(null);
      const selectedIndex = weekdays.indexOf(value);
      if (key === 'startDay') {
        setSelectedDayIndex(selectedIndex);
      }

      setShift((prev) => {
        const updatedSchedule = { ...prev, [`${key}Index`]: selectedIndex, [key]: value };
        if (key === 'startDay') {
          updatedSchedule.endDay = value;
          updatedSchedule.endDayIndex = selectedIndex;
        }
        return updatedSchedule;
      });
    },
    [weekdays],
  );

  const checkMissingInput = useCallback(() => {
    const schedule = scheduleList?.length ? [] : [capitalCase('weeklySchedule')];
    return setMissingInputs((prev) => {
      return { ...prev, schedule };
    });
  }, [scheduleList, setMissingInputs]);

  useEffect(() => {
    checkMissingInput();
    resetDefaults();
  }, [checkMissingInput, resetDefaults]);

  const validInputs = useCallback(() => {
    const endDayIndex = shift.endDayIndex;
    const endTime = shift.endTime;
    const startDayIndex = shift.startDayIndex;
    const startTime = shift.startTime;
    if (!startTime || !endTime) {
      setError('Start and end time required');
      return false;
    }
    if (startDayIndex > endDayIndex || (startDayIndex === endDayIndex && endTime < startTime)) {
      setError('Start time must be before end time');
      return false;
    }
    return true;
  }, [shift]);

  const formatShift = useCallback(() => {
    const { startDay, startDayIndex, startTime, endDay, endDayIndex, endTime } = shift;
    const formattedStartTime = formatStandardTime(startTime);
    const formattedEndTime = formatStandardTime(endTime);
    const formattedStart = `${startDay} ${formattedStartTime}`;
    const formattedEnd =
      startDay === endDay ? `${formattedEndTime}` : `${endDay} ${formattedEndTime}`;

    const formattedDuration = {
      startDay,
      startDayIndex,
      endDay,
      endDayIndex,
      endTime: formatMilitaryTime(shift.endTime),
      startTime: formatMilitaryTime(shift.startTime),
      parsedLabel: `${formattedStart} - ${formattedEnd}`,
    };

    const formattedRecurring = recurringShiftIndexList.map((index) => {
      const startInd = parseInt(index);
      const delta = startInd >= 6 ? -6 : 1;
      const endInd = startDay === endDay ? startInd : startInd + delta;

      const formattedStart = `${weekdays[startInd]} ${formattedStartTime}`;
      const formattedEnd =
        startDay === endDay ? `${formattedEndTime}` : `${weekdays[endInd]} ${formattedEndTime}`;

      return {
        startDay: weekdays[startInd],
        startDayIndex: startInd,
        endDay: weekdays[endInd],
        endDayIndex: endInd,
        endTime: formatMilitaryTime(shift.endTime),
        startTime: formatMilitaryTime(shift.startTime),
        parsedLabel: `${formattedStart} - ${formattedEnd}`,
      };
    });

    return { formattedDuration, formattedRecurring };
  }, [shift, recurringShiftIndexList, weekdays]);

  const parseParams = useCallback(
    (scheduleList) => {
      const draftShifts = scheduleList.map(({ startDay, startTime, endDay, endTime }) => {
        return snakeCase({
          startDay,
          startTime,
          endDay,
          endTime,
        });
      });

      setMatchParams((prev) => {
        return { ...prev, draftShifts };
      });
    },
    [setMatchParams],
  );

  const appendToScheduleList = useCallback(() => {
    let distinctSortedList = [];

    if (validInputs()) {
      const { formattedDuration, formattedRecurring } = formatShift();

      setScheduleList((prev) => {
        let schedule =
          recurringShiftIndexList.length > 1
            ? [...prev, ...formattedRecurring]
            : [...prev, formattedDuration];

        distinctSortedList = [
          ...new Map(schedule.map((sched) => [sched?.parsedLabel, sched])).values(),
        ].sort(
          (a, b) =>
            a.startDayIndex - b.startDayIndex ||
            dayjs(a.startTime, 'HH:mm') - dayjs(b.startTime, 'HH:mm'),
        );

        return distinctSortedList;
      });
      resetDefaults();
    }

    checkMissingInput();
    parseParams(distinctSortedList);
  }, [
    validInputs,
    recurringShiftIndexList,
    formatShift,
    parseParams,
    resetDefaults,
    checkMissingInput,
  ]);

  const toggleRecurring = useCallback(
    (e) => {
      toggleIsRecurring(e.target.checked);
      setRecurringShiftIndexList(() => {
        return e.target.checked ? [selectedDayIndex] : [];
      });
    },
    [setRecurringShiftIndexList, selectedDayIndex],
  );

  const handleRepeatDuration = useCallback(
    (e) => {
      const selectedIndex = e.target.value;
      const isSelected = e.target.checked;

      if (isSelected) {
        setRecurringShiftIndexList((prev) => {
          return [...prev, selectedIndex];
        });
      } else {
        const updatedIndexes = recurringShiftIndexList.filter((index) => index !== selectedIndex);
        setRecurringShiftIndexList(updatedIndexes);
      }
    },
    [recurringShiftIndexList],
  );

  const removeFromScheduleList = useCallback(
    (e) => {
      if (scheduleList.length === 1) {
        setScheduleList([]);
      } else {
        const selectedIndex = e.currentTarget.id;
        const updatedWeeklySchedule = scheduleList.splice(selectedIndex, 1);

        setScheduleList(updatedWeeklySchedule);
      }
    },
    [scheduleList, setScheduleList],
  );

  return (
    <>
      <StyledLabel>Weekly Schedule</StyledLabel>
      {scheduleList?.length ? (
        <StyledIntakeSchedule className="align-items-center">
          {scheduleList.map((sched, index) => (
            <StyledScheduleRow className="weeklyScheduleRow" key={index}>
              <StyledFormLabel className="shift">{sched.parsedLabel}</StyledFormLabel>
              <button type="button" className="close" id={index} onClick={removeFromScheduleList}>
                <CloseIcon id={index} />
              </button>
            </StyledScheduleRow>
          ))}
        </StyledIntakeSchedule>
      ) : null}
      <StyledIntakeSchedule>
        <Row className="mb-4">
          <StyledErrorLabel>{error ? error : null}</StyledErrorLabel>
        </Row>
        {durationDelimiters.map(({ label, dayKey, timeKey }) => (
          <Row className="mb-4" key={label}>
            <Col>
              <Form.Group as={Row}>
                <StyledFormLabel>{label}</StyledFormLabel>
                <Col>
                  <StyledFormSelect
                    onChange={(e) => updateSchedule(e.target.value, e.target.id)}
                    id={dayKey}
                    value={shift[dayKey]}
                  >
                    {weekdays.map((option) => (
                      <option key={`${option}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </StyledFormSelect>
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <StyledFormLabel>at</StyledFormLabel>
                <Col>
                  <TimePicker
                    slots={{ textField: StyledDatePickerField }}
                    value={shift[timeKey]}
                    name={timeKey}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    onChange={(value) => updateSchedule(value, timeKey)}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        ))}
        <Row className="mb-4">
          <Col>
            <Form.Check
              type="checkbox"
              id="recurring"
              label="Recurring?"
              checked={isRecurring}
              onChange={toggleRecurring}
            />
          </Col>
        </Row>

        {isRecurring ? (
          <>
            <StyledLabel>Select start day(s) for recurring shift:</StyledLabel>
            <StyledRecurringOptions>
              {dayjs.weekdaysMin().map((days, ind) => (
                <Col key={`${days}-${ind}`}>
                  <Form.Check
                    type="checkbox"
                    id={`recurring-options-${ind}`}
                    defaultChecked={
                      ind === recurringShiftIndexList.includes(ind) || ind === selectedDayIndex
                    }
                    label={days}
                    value={ind}
                    onClick={handleRepeatDuration}
                  />
                </Col>
              ))}
            </StyledRecurringOptions>
          </>
        ) : null}

        <Row className="mb-5 float-right">
          <Col>
            <StyledButton
              as="input"
              type="button"
              value="Add Shift"
              className="scheduleButton"
              onClick={appendToScheduleList}
            />
          </Col>
        </Row>
      </StyledIntakeSchedule>
    </>
  );
};

const { colors, spacing, sizing, weights } = styles;

const StyledIntakeSchedule = styled.div`
  border: 1px solid ${colors.mediumGray};
  padding: ${spacing.quadSpace} ${spacing.doubleSpace};
  border-radius: 5px;
  margin-bottom: ${spacing.quadSpace};
`;
const StyledFormLabel = styled(Form.Label)`
  line-height: 2.4;
  font-weight: ${weights.button};
`;

const StyledErrorLabel = styled(Form.Label)`
  color: ${colors.red};
  font-weight: ${weights.button};
`;

const StyledFormSelect = styled(Form.Select)`
  padding: ${spacing.singleSpace};
  border: 1px solid ${colors.mediumGray};
  font-size: ${sizing.labelTextSize};
  border-radius: 8px;
  width: 100%;
  color: ${colors.placeholderGray};
`;
const StyledRecurringOptions = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: `${spacing.tripleSpace}`,
  flexWrap: 'wrap',
  justifySelf: 'flex-start',
});

const StyledScheduleRow = styled(Row)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  pointerEvents: 'none',
  padding: `0 ${spacing.microSpace}`,
  borderRadius: '5px',
  border: `1px solid transparent`,

  '&:hover': {
    backgroundColor: `${colors.lightRed}`,
    opacity: '0.6',
  },

  button: {
    opacity: '1',
  },
  svg: {
    marginBottom: `${spacing.halfSpace}`,
    marginLeft: `${spacing.doubleSpace}`,
    padding: `${spacing.microSpace}`,
    strokeWidth: '1.5',
    borderRadius: '50%',
    border: '1px solid gray',
    pointerEvents: 'auto',
    '&:hover': {
      backgroundColor: `${colors.whiteOpaque}`,
    },
  },
});

const StyledDatePickerField = styled(TextField)({
  fontSize: `${sizing.inputTextSize}`,
  width: '100%',

  '.MuiOutlinedInput-input': {
    padding: `${spacing.singleSpace}`,
  },
  'input::placeholder': {
    opacity: '0.8',
    color: `${colors.placeholderGray} !important`,
  },
  'div.MuiInputBase-root': {
    borderRadius: `8px`,
  },

  fieldset: {
    border: `1px solid ${colors.mediumGray} !important`,
    borderRadius: `8px`,
  },
});
