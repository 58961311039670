import React from 'react';

import { formatDateTime } from 'utils';

import { Paragraph, styles } from 'components';
import styled from 'styled-components';

export const Greeting = ({ confirmation }) => {
  const { shift } = confirmation;
  const { recipient } = shift;
  const { month, day, hours, minutes, period } = formatDateTime(shift.start_date);
  const name = confirmation.provider.nickname || confirmation.provider.first_name;

  return (
    <StyledGreeting>
      <Paragraph>
        Hi {name}! You have a shift coming up on {month} {day} at {hours}:{minutes} {period} with{' '}
        {recipient.first_name} {recipient.last_name}. Please confirm you can make it!
      </Paragraph>
    </StyledGreeting>
  );
};

const { spacing } = styles;
const StyledGreeting = styled.div`
  margin-bottom: ${spacing.tripleSpace};
  p {
    font-weight: 500;
    line-height: 32px;
  }
`;
