import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';

import { HttpClientContext, ShiftConfirmationCard, styles } from 'components';
import styled from 'styled-components';

export const ShiftConfirmation = () => {
  const { get, post } = useContext(HttpClientContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedAction, setSubmittedAction] = useState(null);
  const { id } = useParams();
  const [confirmation, setConfirmation] = useState(null);

  useEffect(() => {
    const getConfirmation = async () => {
      setLoading(true);
      try {
        const res = await get({
          url: `confirmations/${id}/`,
        });
        setConfirmation(res['data']['confirmation']);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getConfirmation();
  }, [get, id]);

  const submit =
    ({ action, detail = null }) =>
    async () => {
      setLoading(true);
      const payload = detail ? { action, detail } : { action };
      try {
        await post({
          url: `confirmations/${id}/`,
          payload,
        });
        setSubmitted(true);
        setSubmittedAction(action);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

  if (!confirmation) {
    return null;
  }

  return (
    <StyledShiftConfirmation>
      <Container>
        <StyledConfirmationCardContainer>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <ShiftConfirmationCard
                confirmation={confirmation}
                error={error}
                loading={loading}
                submit={submit}
                submitted={submitted}
                submittedAction={submittedAction}
              />
            </Col>
          </Row>
        </StyledConfirmationCardContainer>
      </Container>
    </StyledShiftConfirmation>
  );
};

const { spacing, colors, screens } = styles;
const StyledShiftConfirmation = styled.div`
  background-color: ${colors.lightGray};
  padding: ${spacing.quadSpace} 0 ${spacing.doubleSpace};

  h4 {
    margin-bottom: ${spacing.doubleSpace};
    line-height: 32px;
  }

  p,
  li {
    margin-bottom: ${spacing.singleSpace};
  }
`;

const StyledConfirmationCardContainer = styled.div`
  background-color: white;
  padding: ${spacing.doubleSpace};
  box-shadow:
    0px -2px 4px rgba(0, 0, 0, 0.1),
    -2px 0px 4px rgba(0, 0, 0, 0.1),
    2px 0px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.15);

  ${screens.md} {
    padding: ${spacing.quadSpace} ${spacing.doubleSpace};
  }
`;
