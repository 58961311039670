import { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { capitalCase } from 'change-case';
import { SectionSubheader, styles } from 'components';
import styled from 'styled-components';

import { StyledLabel } from '../RecipientIntake';

export const ScheduleIntake = ({ setMissingInputs, setMatchParams }) => {
  const [startDate, setStartDate] = useState(null);

  const checkMissingInput = useCallback(() => {
    const scheduleStart = startDate === null ? [capitalCase('startDate')] : [];
    return setMissingInputs((prev) => {
      return { ...prev, scheduleStart };
    });
  }, [startDate, setMissingInputs]);

  const parseScheduleStartParam = useCallback(
    (formattedStartDate) => {
      return setMatchParams((prev) => {
        return {
          ...prev,
          scheduleStartDate: formattedStartDate,
        };
      });
    },
    [setMatchParams],
  );

  const updateStartDay = useCallback(
    (date) => {
      const formattedStartDate = date.format('YYYY-MM-DD');
      setStartDate(formattedStartDate);

      checkMissingInput();
      parseScheduleStartParam(formattedStartDate);
    },
    [checkMissingInput, parseScheduleStartParam],
  );

  useEffect(() => {
    checkMissingInput();
  }, [checkMissingInput]);

  return (
    <>
      <SectionSubheader className="bold mt-5 mb-4">Schedule</SectionSubheader>
      <StyledLabel>Start Date</StyledLabel>
      <Row className="mb-4">
        <Form.Group as={Col} className="col-sm-6">
          <DatePicker
            controlId="startDate"
            disablePast={true}
            slots={{ textField: StyledDatePickerField }}
            onChange={updateStartDay}
          />
        </Form.Group>
      </Row>
    </>
  );
};

const { colors, spacing, sizing } = styles;

const StyledDatePickerField = styled(TextField)({
  fontSize: `${sizing.inputTextSize}`,
  width: '100%',

  '.MuiOutlinedInput-input': {
    padding: `${spacing.singleSpace}`,
  },
  'input::placeholder': {
    opacity: '0.8',
    color: `${colors.placeholderGray} !important`,
  },
  'div.MuiInputBase-root': {
    borderRadius: `8px`,
  },

  fieldset: {
    border: `1px solid ${colors.mediumGray} !important`,
    borderRadius: `8px`,
  },
});
