import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { formatDateTime } from 'utils/dateTime';

import { styles } from 'components';
import styled from 'styled-components';

const { spacing, sizing, weights, colors } = styles;

export const Metadata = ({ metadata }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const mapTransportMode = (mode) => {
    const modeMap = {
      own_vehicle: 'Car',
      public_transportation: 'Public Transit',
    };

    return modeMap[mode] || mode;
  };

  const travelTimeInMinutes = (metadata.travel_time / 60).toFixed(0);
  const transportationMode = mapTransportMode(metadata.transportation_mode);

  return (
    <MetadataContainer>
      <DetailsButton onClick={toggleDetails}>
        {showDetails ? 'Hide Details' : 'Show Details >'}
      </DetailsButton>
      <CSSTransition in={showDetails} timeout={100} classNames="details" unmountOnExit>
        <DetailsContent>
          <DetailItem>
            <strong>Total Scheduled Hours:</strong> {metadata.total_scheduled_hours}
          </DetailItem>
          <DetailItem>
            <strong>Shifts Next 24 Hours: </strong>
            <Shifts data={metadata} />
          </DetailItem>
          <DetailItem>
            <strong>Travel Time:</strong> {travelTimeInMinutes} minutes
          </DetailItem>
          <DetailItem>
            <strong>Transportation Mode:</strong> {transportationMode}
          </DetailItem>
        </DetailsContent>
      </CSSTransition>
    </MetadataContainer>
  );
};

const Shifts = ({ data }) => {
  if (!data.shifts_next_24_hours.length) {
    return <span style={{ marginBottom: '0px' }}>None</span>;
  }
  return (
    <ShiftList>
      {data.shifts_next_24_hours.map((shift, index) => {
        const startDateTime = formatDateTime(shift.start_date);
        const endDateTime = formatDateTime(shift.end_date);
        return (
          <ShiftItem key={index}>
            <div>
              <strong>Date:</strong> {`${startDateTime.month} ${startDateTime.day}`}
            </div>
            <div>
              <strong>Start Time:</strong>{' '}
              {`${startDateTime.hours}:${startDateTime.minutes} ${startDateTime.period}`}
            </div>
            <div>
              <strong>End Time:</strong>{' '}
              {`${endDateTime.hours}:${endDateTime.minutes} ${endDateTime.period}`}
            </div>
            <div>
              <strong>Recipient:</strong> {shift.recipient.first_name} {shift.recipient.last_name}
            </div>
          </ShiftItem>
        );
      })}
    </ShiftList>
  );
};

const MetadataContainer = styled.div`
  margin-top: ${spacing.singleSpace};

  .details-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .details-enter-active {
    max-height: 100px;
    opacity: 1;
    transition:
      max-height 100ms ease-in,
      opacity 100ms ease-in;
  }
  .details-exit {
    max-height: 100px;
    opacity: 1;
  }
  .details-exit-active {
    max-height: 0;
    opacity: 0;
    transition:
      max-height 100ms ease-in,
      opacity 100ms ease-in;
  }
`;

const DetailsButton = styled.button`
  background: none;
  border: none;
  color: ${colors.blue};
  cursor: pointer;
  font-size: ${sizing.listTextSize};
  font-weight: ${weights.semiBold};
  margin-bottom: ${spacing.singleSpace};
  text-align: left;
`;

const DetailsContent = styled.div`
  border-top: 1px solid ${colors.lightGray};
  padding: ${spacing.singleSpace};
`;

const DetailItem = styled.div`
  margin-bottom: ${spacing.halfSpace};
`;

const ShiftList = styled.div`
  margin-top: ${spacing.halfSpace};
`;

const ShiftItem = styled.div`
  margin-bottom: ${spacing.singleSpace};
  padding: ${spacing.halfSpace};
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
`;
