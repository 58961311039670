import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Confirmation, Incentives } from './components';

const COMPONENTS = {
  incentives: 'incentives',
  reviewOffers: 'reviewOffers',
  confirmation: 'confirmation',
};

export const ShiftOffers = () => {
  const [activeComponent, setActiveComponent] = useState(COMPONENTS.incentives);
  const [selectedIncentiveUnit, setSelectedIncentiveUnit] = useState(null);
  const [selectedIncentiveValue, setSelectedIncentiveValue] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shiftId = queryParams.get('shiftId');
  const providerIds = queryParams.get('providers')?.split(',');

  const onIncentiveSubmitted = () => {
    setActiveComponent(COMPONENTS.confirmation);
  };

  switch (activeComponent) {
    case COMPONENTS.incentives:
      return (
        <Incentives
          onIncentiveSubmitted={onIncentiveSubmitted}
          selectedIncentiveUnit={selectedIncentiveUnit}
          selectedIncentiveValue={selectedIncentiveValue}
          setSelectedIncentiveUnit={setSelectedIncentiveUnit}
          setSelectedIncentiveValue={setSelectedIncentiveValue}
          shiftId={shiftId}
          providerIds={providerIds}
        />
      );
    case COMPONENTS.confirmation:
      return <Confirmation />;
    default:
      return null;
  }
};
