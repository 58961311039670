import { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { formatDateTime, formatName, MESSAGE_CLASSIFICATION } from 'utils';

import { HttpClientContext, Paragraph, StyledDropdown, styles } from 'components';
import styled from 'styled-components';

import { CONFIRMATION_STATES, SHIFT_STATES, STATE_KEYWORD_MAP } from './ConfirmationStates';

export const Shift = ({
  id,
  setError,
  state,
  start_date,
  end_date,
  provider,
  recipient,
  reminder_states,
}) => {
  const { post } = useContext(HttpClientContext);

  const [firstCheck, setFirstCheck] = useState(reminder_states['booking_confirmation']);
  const [secondCheck, setSecondCheck] = useState(reminder_states['early_reminder']);
  const [thirdCheck, setThirdCheck] = useState(reminder_states['day_before_reminder']);
  const caregiverMessages = {
    classification: MESSAGE_CLASSIFICATION.default,
    messages: [],
  };

  const reminderState = (reminder) => {
    if (reminder.includes(STATE_KEYWORD_MAP.declined)) {
      return SHIFT_STATES.declined;
    }

    if (reminder.includes(STATE_KEYWORD_MAP.timedOut)) {
      return SHIFT_STATES.atRisk;
    }

    return SHIFT_STATES.ok;
  };

  const responseReceived = (reminder) => {
    if (reminder.includes(STATE_KEYWORD_MAP.notSent) || reminder.includes(STATE_KEYWORD_MAP.sent)) {
      return false;
    }
    return true;
  };

  const status = (() => {
    if (
      state === STATE_KEYWORD_MAP.cancelledByCompany ||
      state === STATE_KEYWORD_MAP.cancelledByCustomer
    ) {
      return SHIFT_STATES.cancelled;
    }

    if (state === STATE_KEYWORD_MAP.cancelledByProvider || state === STATE_KEYWORD_MAP.declined) {
      return SHIFT_STATES.declined;
    }

    if (caregiverMessages.classification === MESSAGE_CLASSIFICATION.callout) {
      return SHIFT_STATES.declined;
    }

    if (responseReceived(thirdCheck)) {
      return reminderState(thirdCheck);
    }

    if (responseReceived(secondCheck)) {
      return reminderState(secondCheck);
    }

    if (responseReceived(firstCheck)) {
      return reminderState(firstCheck);
    }

    return SHIFT_STATES.ok;
  })();

  const reminderClassName = (toCheck) => {
    if (toCheck.includes('cancelled')) {
      return 'cancelled';
    }

    if (toCheck.includes('declined')) {
      return 'declined';
    }

    if (toCheck.includes('timed_out')) {
      return 'at-risk';
    }

    if (toCheck.includes('sent')) {
      return 'ok';
    }

    if (toCheck.includes('confirmed')) {
      return 'ok';
    }

    return 'not-sent';
  };

  const shiftStatusClass = (status) => {
    switch (status) {
      case SHIFT_STATES.atRisk:
        return 'at-risk';
      case SHIFT_STATES.cancelled:
        return 'cancelled';
      case SHIFT_STATES.declined:
        return 'declined';
      default:
        return 'ok';
    }
  };

  const updateShift = async (value) => {
    if (value === STATE_KEYWORD_MAP.notSent) {
      return;
    }
    try {
      await post({
        url: `shifts/${id}/`,
        payload: { update: value },
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const onChangeFirstCheck = (value) => {
    setFirstCheck(value);
    updateShift(value);
  };

  const onChangeSecondCheck = (value) => {
    setSecondCheck(value);
    updateShift(value);
  };

  const onChangeThirdCheck = (value) => {
    setThirdCheck(value);
    updateShift(value);
  };

  const { month, day, hours, minutes, period } = formatDateTime(start_date);
  const startDate = `${month} ${day}`;
  const startTime = `${hours}:${minutes} ${period}`;

  const ShiftAction = () => {
    switch (state) {
      case STATE_KEYWORD_MAP.sourcingProvider:
        return <a href={`/offers/${id}`}>View offers</a>;
      default:
        return <a href={`/provider-recommendations/${id}`}>Replace caregiver</a>;
    }
  };

  return (
    <StyledShiftItem className={shiftStatusClass(status)}>
      <Row>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={shiftStatusClass(status)}>
            <Paragraph>{status}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{formatName(provider)}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{formatName(recipient)}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>{startDate}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{startTime}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={reminderClassName(firstCheck)}>
            <CheckInState
              messages={[]}
              currentState={firstCheck}
              onChange={onChangeFirstCheck}
              options={CONFIRMATION_STATES}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={reminderClassName(secondCheck)}>
            <CheckInState
              messages={[]}
              currentState={secondCheck}
              onChange={onChangeSecondCheck}
              options={CONFIRMATION_STATES}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={reminderClassName(thirdCheck)}>
            <CheckInState
              messages={[]}
              currentState={thirdCheck}
              onChange={onChangeThirdCheck}
              options={CONFIRMATION_STATES}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>
              <ShiftAction />
            </Paragraph>
          </StyledColumn>
        </Col>
      </Row>
    </StyledShiftItem>
  );
};

const CheckInState = ({ currentState, messages, onChange, options }) => {
  return (
    <div>
      <StyledDropdown
        id="check-state"
        value={currentState}
        onChange={(event) => onChange(event.target.value)}
      >
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </StyledDropdown>
    </div>
  );
};

const { spacing, colors } = styles;
export const StyledColumn = styled.div`
  padding-top: ${spacing.doubleSpace};
  padding-bottom: ${spacing.doubleSpace};
  text-align: left;

  p.alert {
    color: ${colors.red};
  }

  &.declined {
    p,
    select {
      color: ${colors.red};
      font-weight: bold;
    }
  }

  &.ok {
    p,
    select {
      color: ${colors.green};
    }
  }

  &.at-risk {
    p,
    select {
      color: ${colors.orange};
    }
  }

  &.not-sent {
    background-color: none;
  }

  button {
    border: none;
    background: none;
    display: inline-block;
    margin-top: ${spacing.singleSpace};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledShiftItem = styled.div`
  border-bottom: 1px solid ${colors.lightGray};

  &.cancelled {
    color: ${colors.mediumGray} !important;

    select {
      color: ${colors.mediumGray} !important;
      font-weight: normal;
    }
  }

  p {
    font-size: 16px;
  }
`;
