export const SHIFT_STATES = {
  atRisk: 'At Risk',
  cancelled: 'Cancelled',
  declined: 'Cancellation Requested',
  ok: 'OK',
};

export const STATE_KEYWORD_MAP = {
  cancelled: 'cancelled',
  cancelledByCompany: 'cancelled_by_company',
  cancelledByCustomer: 'cancelled_by_customer',
  cancelledByProvider: 'cancelled_by_provider',
  confirmed: 'confirmed',
  declined: 'declined',
  notSent: 'not_sent',
  sent: 'sent',
  sourcingProvider: 'sourcing_provider',
  timedOut: 'timed_out',
};

export const CONFIRMATION_STATES = {
  not_sent: 'Not Sent',
  sent: 'Sent',
  timed_out: 'Missed',
  confirmed: 'Confirmed',
  declined: 'Cancelled',
};

export const BOOKING_CONFIRMATION_STATES = {
  not_sent: 'Not Sent',
  booking_confirmation_sent: 'Sent',
  booking_confirmation_timed_out: 'Missed',
  booking_confirmation_confirmed: 'Confirmed',
  booking_confirmation_declined: 'Cancelled',
};

export const EARLY_REMINDER_STATES = {
  not_sent: 'Not Sent',
  early_reminder_sent: 'Sent',
  early_reminder_timed_out: 'Missed',
  early_reminder_confirmed: 'Confirmed',
  early_reminder_declined: 'Cancelled',
};

export const DAY_BEFORE_REMINDER_STATES = {
  not_sent: 'Not Sent',
  day_before_reminder_sent: 'Sent',
  day_before_reminder_timed_out: 'Missed',
  day_before_reminder_confirmed: 'Confirmed',
  day_before_reminder_declined: 'Cancelled',
};
