import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Paragraph, styles, ThreePartHeader } from 'components';
import styled from 'styled-components';

const resultImage = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/family-1.jpg`;

export const Result = () => {
  return (
    <StyledResult>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <ThreePartHeader title="The Result" />
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <Paragraph>
              With Sanctum, urgent communication is always handled, immediately. Clients get better
              care. The team is under less pressure, reducing anxiety and burnout. Turnover
              decreases, revenues go up. Your business grows, faster.
            </Paragraph>
            <img src={resultImage} alt="happy team" />
          </Col>
        </Row>
      </Container>
    </StyledResult>
  );
};

const { spacing } = styles;
const StyledResult = styled.div`
  margin-bottom: 120px;

  img {
    max-width: 100%;
    margin-top: ${spacing.doubleSpace};
  }
`;
