import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useSearchParams } from 'react-router-dom';

import qs from 'qs';

import { ErrorMessage, HttpClientContext, styles, Subheader } from 'components';
import styled from 'styled-components';

export const ZoomAuth = () => {
  const [searchParams] = useSearchParams();
  const { post } = useContext(HttpClientContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  const handleAuthorize = () => {
    const querystring = qs.stringify(
      {
        client_id: process.env.REACT_APP_ZOOM_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_ZOOM_REDIRECT_URI,
        response_type: 'code',
      },
      { addQueryPrefix: true },
    );
    const zoomAuthUrl = `https://zoom.us/oauth/authorize${querystring}`;
    window.location.href = zoomAuthUrl;
  };

  const code = searchParams.get('code');

  useEffect(() => {
    const postAuthCode = async () => {
      setLoading(true);
      try {
        await post({
          url: 'services/zoom/auth/',
          payload: {
            code,
          },
        });
        setAuthorized(true);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (code && !loading && !error && !authorized) {
      postAuthCode();
    }
  }, [authorized, code, error, loading, post]);

  return (
    <Container>
      <Row>
        <Col md={{ offset: 3, span: 6 }}>
          <StyledAuthActions>
            <AuthActions
              authorized={authorized}
              code={code}
              error={error}
              handleAuthorize={handleAuthorize}
              loading={loading}
            />
          </StyledAuthActions>
        </Col>
      </Row>
    </Container>
  );
};

const AuthActions = ({ authorized, code, error, handleAuthorize, loading }) => {
  if (code && loading) {
    return <Subheader>Authorizing...</Subheader>;
  }

  if (code && authorized) {
    return <Subheader>Success! Your Zoom account is now authorized</Subheader>;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <StyledButton>
      <button onClick={handleAuthorize}>Authorize Zoom</button>
    </StyledButton>
  );
};

const { colors, spacing } = styles;
const StyledAuthActions = styled.div`
  padding: ${spacing.quadSpace} 0;

  h3,
  p {
    margin-bottom: ${spacing.doubleSpace};
  }
`;

const StyledButton = styled.div`
  margin-top: ${spacing.tripleSpace};
  margin-bottom: ${spacing.tripleSpace};
  button {
    width: 280px;
    background-color: white;
    border: 1px solid ${colors.lightGray};
    margin: 15px 0;
    border-radius: 25px;
    padding: ${spacing.singleSpace} 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
      max-width: 30px;
    }

    &:disabled {
      background-color: ${colors.lightGray};
    }
  }
`;
