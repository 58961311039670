import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ReactSelect, { components } from 'react-select';

import styled from 'styled-components';

import { colors, sizing, spacing, weights } from './sharedStyles';

const arrow = `${process.env.REACT_APP_ASSETS_URL}/img/arrow.png`;
const whiteArrow = `${process.env.REACT_APP_ASSETS_URL}/img/arrow-white.png`;

/* -------------- TEXT -------------- */

export const CTATitle = styled.h1`
  color: ${colors.green};
  font-size: 38px;
`;

export const PageHeader = styled.h1`
  font-weight: 500;
  font-size: 58px;
  line-height: 64px;
`;

export const SectionHeader = styled.h2`
  font-size: 42px;
  font-weight: 400;
`;

export const Subheader = styled.h3`
  font-size: ${sizing.subheaderTextSize};
  line-height: 42px;
  font-weight: 500;
`;

export const SectionSubheader = styled.h4`
  font-size: ${sizing.sectionSubheaderTextSize};
  line-height: 36px;
`;

export const CardHeader = styled.h5`
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-size: ${sizing.paragraphTextSize};
  font-weight: 400;
  margin-bottom: 0; // reset default styles

  &.small {
    font-size: 14px;
  }

  &.large {
    font-size: 20px;
  }

  &.bold {
    font-weight: ${weights.bold};
  }
  &.line-break {
    white-space: pre-line;
  }
`;

export const Span = styled.span`
  font-size: ${sizing.paragraphTextSize};
  font-weight: 400;
  margin-bottom: 0; // reset default styles

  &.small {
    font-size: 14px;
  }

  &.large {
    font-size: 20px;
  }

  &.bold {
    font-weight: ${weights.bold};
  }
`;

export const BasicLink = styled.a`
  color: ${colors.blue};
  font-size: ${sizing.paragraphTextSize};
  text-decoration: underline;
  cursor: pointer;
`;

export const Bold = styled.span`
  font-weight: ${weights.bold};
`;

/* -------------- BUTTONS -------------- */

export const ActionButton = ({ disabled, onClick, text, ...rest }) => {
  return (
    <StyledActionButton disabled={disabled} onClick={onClick} {...rest}>
      {text}
    </StyledActionButton>
  );
};

const StyledActionButton = styled.button`
  background-color: ${colors.blue};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: ${SectionSubheader};
  padding: ${spacing.doubleSpace};
  font-weight: ${weights.bold};
  width: 100%;

  &:disabled {
    background-color: ${colors.blueOpaque};
  }
`;

export const PrimaryButton = ({ text, link, type, ...rest }) => {
  if (!link) {
    return (
      <StyledPrimaryButtonLink className={type}>
        <button {...rest}>{text}</button>
      </StyledPrimaryButtonLink>
    );
  }

  return (
    <StyledPrimaryButtonLink className={type}>
      <HashLink to={link}>
        <button {...rest}>{text}</button>
      </HashLink>
    </StyledPrimaryButtonLink>
  );
};

const StyledPrimaryButtonLink = styled.div`
  a {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }

  &.white {
    button {
      background-color: white;
      color: black;
    }
  }

  &.black-outline {
    button {
      background-color: white;
      border: 1px solid black;
      color: black;
    }
  }

  button {
    align-items: center;
    background-color: ${colors.blue};
    border-radius: 25px;
    border: none;
    color: white;
    display: flex;
    font-size: ${sizing.buttonTextSize};
    font-weight: 700;
    justify-content: center;
    letter-spacing: ${sizing.buttonTextKerning};
    margin: 15px 0;
    min-width: 180px;
    padding: ${spacing.singleSpace} ${spacing.doubleSpace};

    img {
      margin-left: 10px;
    }

    &:disabled {
      background-color: ${colors.blueOpaque};
    }
  }
`;

export const SecondaryButton = ({ link, text, type, ...rest }) => {
  let imgSrc;
  switch (type) {
    case 'white-outline':
      imgSrc = whiteArrow;
      break;
    default:
      imgSrc = arrow;
      break;
  }

  if (link) {
    return (
      <StyledSecondaryButton className={type}>
        <StyledSecondaryButtonLink>
          <Link to={link}>
            <button>
              {text}
              <img src={imgSrc} alt="Arrow" />
            </button>
          </Link>
        </StyledSecondaryButtonLink>
      </StyledSecondaryButton>
    );
  }

  return (
    <StyledSecondaryButton className={type}>
      <button {...rest}>
        {text}
        <img src={imgSrc} alt="Arrow" />
      </button>
    </StyledSecondaryButton>
  );
};

export const StyledSecondaryButton = styled.div`
  &.white-outline {
    button {
      border-color: white;
      color: white;
    }
  }

  button {
    background: none;
    color: black;
    border: 1px solid black;
    border-radius: 25px;
    font-size: ${sizing.buttonTextSize};
    font-weight: 400;
    letter-spacing: ${sizing.buttonTextKerning};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    img {
      margin-left: ${spacing.singleSpace};
    }
  }
`;

const StyledSecondaryButtonLink = styled.div`
  a {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const GenericButton = styled.button`
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${colors.blue};
  color: ${colors.darkGray};
  display: flex;
  font-size: ${sizing.buttonTextSize};
  justify-content: center;
  letter-spacing: ${sizing.buttonTextKerning};
  padding: 5px 10px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

  a {
    color: ${colors.blue};
    text-decoration: none;
  }

  &.blue {
    border: none;
    background-color: ${colors.blue};
    color: white;

    a {
      color: white;
    }
  }

  &:disabled {
    border: none;
    background-color: ${colors.blueOpaque};
    color: white;
  }
`;

export const ButtonIconRight = styled.img`
  margin-left: 20px;
`;

export const ButtonIconLeft = styled.img`
  margin-right: 10px;
`;

export const ClickableText = styled.p`
  cursor: pointer;
`;

export const Container = styled.section`
  position: relative;
  text-align: center;
`;

export const StyledDropdown = styled.select`
  background: none;
  padding: 5px;
  border-radius: 5px;
  border: none;
  max-width: 100%;
  font-weight: 600;
`;

export const ErrorMessage = styled.h2`
  background-color: ${colors.red};
  border-radius: 10px;
  color: white;
  padding: 15px;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  margin-top: ${sizing.halfSpace};

  p {
    margin: 0;
  }

  a {
    color: white;
  }
`;

export const FlexContainer = styled.section`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const Input = styled.input`
  height: 50px;
  margin-bottom: 15px;
  min-width: 200px;
  max-width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  width: 100%;

  &[type='checkbox'] {
    width: auto;
    min-width: auto;
    max-width: auto;
    margin-bottom: 0;
    margin-right: ${sizing.halfSpace};
    vertical-align: bottom;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const InputContainer = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: ${sizing.halfSpace};
  margin-bottom: 30px;
  font-size: 18px;
  overflow: hidden;
`;

export const InputContainerTitle = styled.h5`
  padding: 15px 15px 0 15px;
  font-weight: 600;
  text-align: center;
`;

export const InputTextArea = styled.textarea`
  font-size: 18px;
  padding: 5px ${sizing.halfSpace};
  border: 1px solid ${colors.darkBlue};
  border-radius: ${sizing.halfSpace};
  width: 100%;
`;

export const Label = styled.label`
  display: block;
  font-size: 18px;
  margin: 15px 0 ${sizing.halfSpace};

  &.small {
    font-size: 14px;
  }
`;

export const LeftAlign = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const LinkText = styled.div`
  color: black;
  font-size: 22px;
  line-height: 24px;
  text-decoration: none;
  margin-top: ${sizing.halfSpace};
`;

export const List = styled.ul`
  font-size: 18px;
  padding-left: 20px;
  margin: ${spacing.singleSpace} 0 ${spacing.doubleSpace};

  li {
    margin-bottom: ${spacing.halfSpace};
  }
`;

export const ListItem = styled.div`
  font-size: 18px;
  margin: 10px 0;
`;

export const MessageInput = styled.textarea`
  border: 1px solid gray;
  border-radius: ${spacing.halfSpace};
  min-height: 240px;
  outline: none;
  padding: ${spacing.singleSpace};
  resize: none;
  width: 100%;
`;

export const NavLink = styled.a`
  cursor: pointer;
  font-size: ${sizing.paragraphTextSize};
`;

export const Navtitle = styled.h1`
  font-size: 22px;
  margin: auto;
`;

export const Navbar = styled.div`
  width: 100%;
  height: 90px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const Select = ({ styles, inputDisabled, ...rest }) => {
  const CustomInput = (props) => <components.Input {...props} readOnly={inputDisabled} />;

  return (
    <ReactSelect
      components={{ Input: CustomInput }}
      {...rest}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          borderRadius: 10,
          borderWidth: 1,
          borderColor: colors.blue,
          color: colors.darkGray,
          fontSize: sizing.buttonTextSize,
          letterSpacing: sizing.buttonTextKerning,
          height: 39.5,
          ...styles,
        }),
      }}
    />
  );
};

export const ShadowBox = styled.div`
  box-shadow: 0 2px 3px ${colors.gray};
`;

export const Subtitle = styled.h2`
  font-size: 28px;
  line-height: 38px;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const TextWithLinks = styled.div`
  a {
    color: #6e57ff;
  }
`;

export const Title = styled.h1`
  font-size: 42px;
  line-height: 48px;

  @media only screen and (min-width: 768px) {
    font-size: 52px;
    line-height: 66px;
  }
`;
