import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { styles, Subheader } from 'components';
import styled from 'styled-components';

import { CalloutDashboard, Header, ShiftList } from './components';

const SCREENS = {
  calloutDashboard: 'calloutDashboard',
  shiftList: 'shiftList',
};

export const ShiftDashboard = (props) => {
  const [visibleScreen, setVisibleScreen] = useState(SCREENS.shiftList);

  const showShiftList = () => {
    setVisibleScreen(SCREENS.shiftList);
  };

  const showCalloutDashboard = () => {
    setVisibleScreen(SCREENS.calloutDashboard);
  };

  return (
    <StyledDashboardContainer>
      <Container fluid>
        <StyledTitle>
          <Row>
            <Col>
              <Subheader>Shift Status</Subheader>
            </Col>
          </Row>
        </StyledTitle>
        <StyledDashboard>
          <Header
            showCalloutDashboard={showCalloutDashboard}
            showShiftList={showShiftList}
            visibleScreen={visibleScreen}
          />
          {visibleScreen === SCREENS.shiftList ? <ShiftList /> : <CalloutDashboard />}
        </StyledDashboard>
      </Container>
    </StyledDashboardContainer>
  );
};

const { colors, spacing } = styles;
const StyledDashboardContainer = styled.div`
  background-color: ${colors.lightGray};
  padding-top: ${spacing.tripleSpace};
`;

const StyledDashboard = styled.div`
  background-color: white;
  margin-top: ${spacing.doubleSpace};
  padding: ${spacing.doubleSpace} 5px;
`;

const StyledTitle = styled.div`
  padding-left: 5%;
  h3 {
    font-weight: 700;
  }
`;
