import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { HashLink as Link } from 'react-router-hash-link';

import { formatDateTime, formatName } from 'utils';

import {
  ErrorMessage,
  HttpClientContext,
  InlineLoading,
  Paragraph,
  styles,
  Subheader,
} from 'components';
import styled from 'styled-components';

import { Offer, StyledColumn } from './Offer';

const { colors, weights } = styles;

export const OfferList = ({ shiftId }) => {
  const { get } = useContext(HttpClientContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const getOffers = async () => {
      setLoading(true);
      try {
        const res = await get({
          url: `offers/${shiftId}/`,
        });
        setOffers(res.data?.offers || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getOffers();
  }, [get, shiftId]);

  if (loading || !offers || offers.length < 1) {
    return (
      <Container>
        <StyledOffersList>
          <Row>
            <Col xs={12} lg={{ offset: 3, span: 6 }}>
              <InlineLoading />
            </Col>
          </Row>
        </StyledOffersList>
      </Container>
    );
  }

  const recipient = formatName(offers[0].shift.recipient);
  const { month, day, hours, minutes, period } = formatDateTime(offers[0].shift.start_date);
  const shiftStartDate = `${month} ${day}`;
  const shiftStartTime = `${hours}:${minutes} ${period}`;

  return (
    <Container>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <StyledOffersList>
        <div className="header">
          <div className="header-left">
            <Subheader>Shift Offers</Subheader>
            <Paragraph>
              These are the offers sent for the shift with {recipient} for {shiftStartDate} at{' '}
              {shiftStartTime}.
            </Paragraph>
          </div>
          <div className="header-right">
            <Link to="/shift-dashboard">Back to Dashboard</Link>
          </div>
        </div>
        <Row className="header-row">
          <Col xs={{ span: 3 }}>
            <StyledColumn>
              <Paragraph>Provider</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 3 }}>
            <StyledColumn>
              <Paragraph>Send Date</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 3 }}>
            <StyledColumn>
              <Paragraph>Send Time</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 3 }}>
            <StyledColumn>
              <Paragraph>Status</Paragraph>
            </StyledColumn>
          </Col>
        </Row>
        {offers && offers.map((item, i) => <Offer key={i} setError={setError} {...item} />)}
      </StyledOffersList>
    </Container>
  );
};

const { spacing } = styles;
const StyledOffersList = styled.section`
  border: 1px solid ${colors.mediumGray};
  padding: ${spacing.tripleSpace} ${spacing.doubleSpace};
  border-radius: 5px;
  margin-bottom: ${spacing.doubleSpace};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${spacing.doubleSpace};
  }

  .header-row {
    p {
      font-weight: ${weights.bold};
    }
  }
`;
