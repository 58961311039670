import { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { snakeCase } from 'change-case/keys';
import { SectionSubheader, styles } from 'components';
import styled from 'styled-components';

export const RequiredAttributeIntake = ({
  providerAttributes,
  setProviderAttributes,
  setMatchParams,
}) => {
  const [attributesRequired, setAttributesRequired] = useState([]);

  const handleOptionSelect = useCallback(
    (attributesRequired) => {
      if (!attributesRequired.length) {
        setAttributesRequired([]);
      } else {
        const filteredProviderAttributes = providerAttributes.filter((attribute) => {
          return !attributesRequired.includes(attribute);
        });

        setProviderAttributes(filteredProviderAttributes);
        setAttributesRequired(attributesRequired);

        setMatchParams((prev) => {
          const parsedAttributes = attributesRequired.map((attribute) => attribute.value);
          const updatedRecipient = {
            ...prev.recipient,
            ...snakeCase({ attributesRequired: parsedAttributes }),
          };

          return {
            ...prev,
            recipient: updatedRecipient,
            providerAttributes: parsedAttributes,
          };
        });
      }
    },
    [providerAttributes, setProviderAttributes, setAttributesRequired, setMatchParams],
  );

  return (
    <StyledRequiredAttributeIntake>
      <SectionSubheader className="bold my-3">Required Provider Attributes</SectionSubheader>
      <Form.Group className="my-4 mb-5" controlId="providerAttributes">
        <Select
          isMulti
          name="attributesRequired"
          hideSelectedOptions={true}
          options={providerAttributes}
          values={attributesRequired}
          onChange={handleOptionSelect}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </Form.Group>
    </StyledRequiredAttributeIntake>
  );
};

const { spacing } = styles;
const StyledRequiredAttributeIntake = styled.div`
  margin-bottom: ${spacing.quadSpace};
`;
