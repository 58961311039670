import { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import { snakeCase } from 'change-case/keys';
import { HttpClientContext, Loading, styles } from 'components';
import styled from 'styled-components';

import {
  IntakeHeader,
  ProfileIntake,
  RequiredAttributeIntake,
  ScheduleIntake,
  WeeklyScheduleIntake,
} from './components';

export const RecipientIntake = () => {
  const navigate = useNavigate();
  const { get, post } = useContext(HttpClientContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [providerAttributes, setProviderAttributes] = useState([]);

  const [missingInputs, setMissingInputs] = useState({
    profile: [],
    schedule: [],
    scheduleStart: [],
  });
  const [matchParams, setMatchParams] = useState({
    recipient: {},
    scheduleStartDate: null,
    draftShifts: [],
    providerAttributes: [],
  });

  useEffect(() => {
    const getSkills = async () => {
      setLoading(true);
      try {
        const res = await get({
          url: `offers/provider-attributes/`,
        });
        const providerAttributes = res.data?.attributes;
        setProviderAttributes(providerAttributes || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getSkills();
  }, [get]);

  const updateLoadingStates = useCallback(
    (loading, error = null) => {
      if (error) {
        setError(error);
      }

      setLoading(loading);
      return;
    },
    [setLoading, setError],
  );

  const handleSubmit = useCallback(() => {
    const getMatches = async () => {
      updateLoadingStates(true);

      try {
        const res = await post({
          url: 'offers/matches/',
          payload: snakeCase(matchParams),
        });

        const recommendations = res.data?.recommendations || [];
        setLoading(false);

        navigate('/recipient-intake/matches', { state: { recommendations } });
      } catch (error) {
        setError(error.message);
      }
      updateLoadingStates(false);
    };

    const requiredInputs = Object.values(missingInputs).flat();

    if (requiredInputs?.length) {
      return setError(`Missing required inputs:\n ${requiredInputs.join(', ')}`);
    } else {
      return getMatches();
    }
  }, [post, matchParams, navigate, updateLoadingStates, missingInputs]);

  return (
    <StyledRecipientIntakeContainer>
      <Container fluid="md">
        <StyledRecipientIntake>
          <Row>
            <Col>
              {loading && !error ? (
                <Loading />
              ) : (
                <Form onSubmit={handleSubmit}>
                  <IntakeHeader error={error} />
                  <ProfileIntake
                    setMatchParams={setMatchParams}
                    setMissingInputs={setMissingInputs}
                  />
                  <ScheduleIntake
                    setMissingInputs={setMissingInputs}
                    setMatchParams={setMatchParams}
                  />
                  <WeeklyScheduleIntake
                    setMissingInputs={setMissingInputs}
                    setMatchParams={setMatchParams}
                    matchParams={matchParams}
                  />
                  <RequiredAttributeIntake
                    providerAttributes={providerAttributes}
                    setProviderAttributes={setProviderAttributes}
                    setMatchParams={setMatchParams}
                  />
                  <Form.Group className="my-4 mb-5" controlId="providerAttributes">
                    <StyledButton
                      type="submit"
                      className="full-width"
                      value="See Matches"
                      onClick={handleSubmit}
                      size="lg"
                    />
                  </Form.Group>
                </Form>
              )}
            </Col>
          </Row>
        </StyledRecipientIntake>
      </Container>
    </StyledRecipientIntakeContainer>
  );
};
const { colors, spacing, sizing, weights } = styles;
const StyledRecipientIntakeContainer = styled.div`
  background-color: ${colors.lightGray};
  padding-top: ${spacing.tripleSpace};

  .header {
    margin-bottom: ${spacing.quadSpace};
  }
`;

const StyledRecipientIntake = styled.div`
  background-color: white;
  padding: ${spacing.quadSpace} ${spacing.doubleQuad};
  border-radius: 5px;
  margin-bottom: ${spacing.doubleSpace};
`;

export const StyledLabel = styled.label`
  font-size: ${sizing.labelTextSize};
  color: #333;
  margin-bottom: ${spacing.singleSpace};
`;

export const StyledButton = styled.input`
  font-size: ${sizing.buttonTextSize};
  font-weight: ${weights.button};
  letter-spacing: ${sizing.buttonTextKerning};
  border-radius: 10px;
  padding: ${spacing.halfSpace} ${spacing.quadSpace};
  color: white;
  border: none;
  background-color: ${colors.blue};
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${spacing.singleSpace};
  &.full-width {
    padding: ${spacing.singleSpace};
    width: 100%;
  }
`;
