import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Subheader } from 'components';
import styled from 'styled-components';

export const Loading = () => {
  return (
    <StyledLoading>
      <Container>
        <Row>
          <Col>
            <InlineLoading />
          </Col>
        </Row>
      </Container>
    </StyledLoading>
  );
};

export const InlineLoading = () => {
  return <Subheader>Loading...</Subheader>;
};

const StyledLoading = styled.div`
  text-align: center;
  height: 80vh;
`;
