import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { formatDateTime } from 'utils';

import { ErrorMessage, HttpClientContext, Paragraph, StyledDropdown, styles } from 'components';
import styled from 'styled-components';

const STATE = ['Unresolved', 'Resolved'];

export const CalloutDashboard = () => {
  const { get } = useContext(HttpClientContext);
  const [error, setError] = useState('');
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    const today = new Date();
    const startDate = today.toISOString();

    const fetchShifts = async () => {
      try {
        const res = await get({
          url: 'shifts/',
          params: {
            state: [
              'declined',
              'booking_confirmation_declined',
              'early_reminder_declined',
              'day_before_reminder_declined',
            ],
            start_date: startDate,
          },
        });
        setShifts(res.data?.shifts);
      } catch (err) {
        setError(err);
      }
    };

    fetchShifts();
  }, [get]);

  return (
    <StyledCommsList>
      <Container fluid>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Row className="header">
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Date</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Time</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Caregiver</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Patient</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Status</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Actions</Paragraph>
            </StyledColumn>
          </Col>
        </Row>
        {shifts.map((shift, i) => (
          <Callout key={i} {...shift} />
        ))}
      </Container>
    </StyledCommsList>
  );
};

const Callout = ({ id, state, start_date, end_date, provider, recipient }) => {
  const [itemState, setItemState] = useState(STATE[0]);

  const onChangeState = (event) => {
    setItemState(event.target.value);
  };

  const { month, day, hours, minutes, period } = formatDateTime(start_date);
  const startDate = `${month} ${day}`;
  const startTime = `${hours}:${minutes} ${period}`;

  return (
    <div className="table-row">
      <Row>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{startDate}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{startTime}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{`${provider.first_name} ${provider.last_name}`}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{`${recipient.first_name} ${recipient.last_name}`}</Paragraph>
          </StyledColumn>
        </Col>

        <Col xs={{ span: 2 }}>
          <StyledColumn className={itemState === 'Unresolved' ? 'unresolved' : 'resolved'}>
            <StatusDropdown currentState={itemState} onChangeState={onChangeState} id={id} />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>
              <a href={`/provider-recommendations/${id}`}>Fill Shift</a>
            </Paragraph>
          </StyledColumn>
        </Col>
      </Row>
    </div>
  );
};

const StatusDropdown = ({ currentState, onChangeState, id }) => {
  return (
    <StyledDropdown id={`state-select-${id}`} value={currentState} onChange={onChangeState}>
      {STATE.map((s, index) => (
        <option key={index} value={s}>
          {s}
        </option>
      ))}
    </StyledDropdown>
  );
};

const { colors, spacing } = styles;
const StyledCommsList = styled.section`
  .header {
    padding: ${spacing.doubleSpace} 0;
    p {
      font-weight: 600;
    }
  }

  .table-row {
    p {
      font-size: 16px;
    }
  }
`;

const StyledColumn = styled.div`
  padding-top: ${spacing.doubleSpace};
  padding-bottom: ${spacing.doubleSpace};
  text-align: left;

  p.alert {
    color: ${colors.red};
  }

  &.unresolved {
    select {
      color: ${colors.red};
    }
  }

  &.resolved {
    select {
      color: ${colors.green};
    }
  }

  &.cancelled {
    p,
    select {
      color: ${colors.red};
      font-weight: bold;
    }
  }

  &.ok {
    p,
    select {
      color: ${colors.green};
    }
  }

  &.at-risk {
    p,
    select {
      color: ${colors.orange};
    }
  }

  &.not-sent {
    background-color: none;
  }

  button {
    border: none;
    background: none;
    display: inline-block;
    margin-top: ${spacing.singleSpace};

    &:hover {
      text-decoration: underline;
    }
  }
`;
