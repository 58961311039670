import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Paragraph, SectionHeader, styles } from 'components';
import styled from 'styled-components';

export const NotFound = () => {
  return (
    <StyledNotFound>
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <SectionHeader>404</SectionHeader>
            <Paragraph>
              Whoops! Looks like that page doesn't exist, or maybe you're not logged in?
            </Paragraph>
          </Col>
        </Row>
      </Container>
    </StyledNotFound>
  );
};

const StyledNotFound = styled.div`
  margin: ${styles.spacing.quadSpace} 0;
`;
