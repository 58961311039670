import { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select';

import { formatDateTime } from 'utils';

import {
  ErrorMessage,
  HttpClientContext,
  InlineLoading,
  Paragraph,
  PrimaryButton,
  SectionSubheader,
  styles,
} from 'components';
import { sizing } from 'components/sharedStyles';
import styled from 'styled-components';

const { colors, spacing } = styles;

export const Incentives = ({
  onIncentiveSubmitted,
  selectedIncentiveUnit,
  selectedIncentiveValue,
  shiftId,
  providerIds,
}) => {
  const { post } = useContext(HttpClientContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [incentiveUnit, setIncentiveUnit] = useState(selectedIncentiveUnit);
  const [incentiveValue, setIncentiveValue] = useState(selectedIncentiveValue || '');
  const [incentiveRules, setIncentiveRules] = useState('');
  const [summary, setSummary] = useState('');
  const [offersData, setOffersData] = useState([]);

  const incentiveUnits = useRef([
    { value: 'cash', label: 'Dollars' },
    { value: 'points', label: 'Points' },
    { value: 'vacation_days', label: 'Vacation Days' },
    { value: 'hourly_rate', label: 'Hourly Rate' },
  ]);

  useEffect(() => {
    const generateOffers = async () => {
      setLoading(true);
      try {
        const response = await post({
          url: '/offers/',
          payload: {
            shift_id: shiftId,
            providers: providerIds,
          },
        });
        const offers = response.data?.offers || [];
        const incentiveRules = response.data?.incentive_rules || '';
        setIncentiveRules(incentiveRules);
        setOffersData(offers);

        if (offers.length > 0 && offers[0].incentive) {
          const { unit, value, justification } = offers[0].incentive;
          const mappedUnit = incentiveUnits.current.find((option) => option.value === unit);
          setSummary(
            `Because ${justification}, the recommended incentive is
            ${value} ${mappedUnit.label}.`,
          );
          setIncentiveValue(value);
          setIncentiveUnit(mappedUnit);
        } else {
          setSummary(
            `This shift does not meet any of your incentive criteria,
            so there are no recommended incentives.`,
          );
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    generateOffers();
  }, [post, shiftId, providerIds]);

  const submit = async () => {
    let updatedOffers = offersData;

    if (offersData.length === 0 && incentiveUnit && incentiveValue) {
      try {
        const response = await post({
          url: '/offers/',
          payload: {
            shift_id: shiftId,
            providers: providerIds,
          },
        });
        updatedOffers = response.data?.offers || [];
      } catch (error) {
        setError(error.message);
        return;
      }
    }

    let offersWithIncentives = updatedOffers;
    if (incentiveUnit?.value && incentiveValue) {
      offersWithIncentives = updatedOffers.map((offer) => ({
        ...offer,
        incentive: {
          ...offer.incentive,
          unit: incentiveUnit.value,
          value: incentiveValue,
        },
      }));
    }

    try {
      await post({
        url: '/offers/send/',
        payload: {
          offers: offersWithIncentives,
        },
      });
      onIncentiveSubmitted();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleIncentiveValueChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setIncentiveValue(value);
    } else {
      setError('Incentive value must be a numeric value');
    }
  };

  if (loading) {
    return (
      <Container>
        <StyledShiftOffers>
          <Row>
            <Col>
              <StyledSummary>
                <InlineLoading />
              </StyledSummary>
            </Col>
          </Row>
        </StyledShiftOffers>
      </Container>
    );
  }

  return (
    <Container>
      <StyledShiftOffers>
        <h2>Provider Incentives</h2>
        <Row>
          <Col>
            <StyledSummary>
              {error ? <ErrorMessage error={error}>{error}</ErrorMessage> : null}
              <Paragraph>
                {summary} You can update the incentive below. When you're ready click "Send" to send
                the offer to the recommended providers.
              </Paragraph>
            </StyledSummary>
            <StyledRules>
              <SectionSubheader>Incentive Rules</SectionSubheader>
              <Paragraph>{incentiveRules}</Paragraph>
            </StyledRules>
            <ShiftDetails offers={offersData} />
            <SectionSubheader>Incentive</SectionSubheader>
            <StyledInputWrapper>
              <StyledSelect
                value={incentiveUnit}
                onChange={(value) => setIncentiveUnit(value)}
                options={incentiveUnits.current}
                placeholder="Incentive Type"
              />
              <StyledInput
                type="number"
                value={incentiveValue}
                onChange={handleIncentiveValueChange}
                placeholder="Amount"
              />
            </StyledInputWrapper>
          </Col>
        </Row>
        <Row>
          <Col>
            <PrimaryButton onClick={submit} text={'Send'} />
          </Col>
        </Row>
      </StyledShiftOffers>
    </Container>
  );
};

const ShiftDetails = ({ offers }) => {
  if (offers.length === 0) {
    return null;
  }
  const shift = offers[0].shift;
  const { month, day, hours, minutes, period } = formatDateTime(shift.start_date);
  const {
    month: endMonth,
    day: endDay,
    hours: endHours,
    minutes: endMinutes,
    period: endPeriod,
  } = formatDateTime(shift.end_date);
  const startDate = `${month} ${day}`;
  const startTime = `${hours}:${minutes} ${period}`;
  const endDate = `${endMonth} ${endDay}`;
  const endTime = `${endHours}:${endMinutes} ${endPeriod}`;

  return (
    <StyledShiftDetails>
      <SectionSubheader>Shift Details</SectionSubheader>
      <Paragraph>
        <ul>
          <li>
            Shift Start: {startDate} at {startTime}
          </li>
          <li>
            Shift End: {endDate} at {endTime}
          </li>
          <li>
            Address: {shift.recipient.address.street_address}, {shift.recipient.address.city},{' '}
            {shift.recipient.address.state}
          </li>
          <li>Hourly Bill Rate: ${shift.hourly_bill_rate}</li>
        </ul>
      </Paragraph>
    </StyledShiftDetails>
  );
};

const StyledShiftOffers = styled.div`
  border: 1px solid ${colors.mediumGray};
  padding: ${spacing.quadSpace} ${spacing.doubleSpace};
  border-radius: 5px;
  margin-bottom: ${spacing.doubleSpace};

  select {
  }
`;

const StyledSummary = styled.div`
  margin: ${spacing.doubleSpace} 0;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  gap: ${spacing.singleSpace};
  margin-bottom: ${spacing.doubleSpace};
  font-size: ${sizing.paragraphTextSize};
`;

const StyledSelect = styled(Select)`
  max-width: 220px;
  flex: 1;
  height: ${spacing.tripleSpace};
`;

const StyledShiftDetails = styled.div`
  margin-bottom: ${spacing.doubleSpace};
`;

const StyledInput = styled.input`
  max-width: 220px;
  flex: 1;
  padding: ${spacing.singleSpace};
  border: 1px solid ${colors.mediumGray};
  border-radius: 5px;
  height: ${spacing.tripleSpace};
`;

const StyledRules = styled.div`
  margin-bottom: ${spacing.tripleSpace};
`;
