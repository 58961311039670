import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';

import {
  ActionButton,
  CONFIRMATION_ACTIONS,
  ErrorMessage,
  HttpClientContext,
  InlineLoading,
  SectionSubheader,
  ShiftConfirmationCard,
  styles,
} from 'components';
import styled from 'styled-components';

export const ShiftListConfirmation = () => {
  const [confirmationSet, setConfirmationSet] = useState([]);
  const [submittedAction, setSubmittedAction] = useState(null);
  const [summary, setSummary] = useState('');
  const [error, setError] = useState(null);
  const { get, post } = useContext(HttpClientContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getConfirmationSet = async () => {
      setLoading(true);
      try {
        const res = await get({
          url: `confirmations/set/${id}/`,
        });
        setConfirmationSet(res['data']['confirmations']);
        setSummary(res['data']['summary']);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getConfirmationSet();
  }, [get, id]);

  const handleConfirmAll = async () => {
    setLoading(true);
    try {
      await post({
        url: `confirmations/set/${id}/`,
        payload: { action: CONFIRMATION_ACTIONS.accept },
      });
      setSubmittedAction(CONFIRMATION_ACTIONS.accept);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <StyledShiftListConfirmation>
        <Container>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <ErrorMessage>{error}</ErrorMessage>
            </Col>
          </Row>
        </Container>
      </StyledShiftListConfirmation>
    );
  }

  if (!confirmationSet || loading) {
    return (
      <StyledShiftListConfirmation>
        <Container>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <InlineLoading />
            </Col>
          </Row>
        </Container>
      </StyledShiftListConfirmation>
    );
  }

  if (confirmationSet.length === 0) {
    return (
      <StyledShiftListConfirmation>
        <Container>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <SectionSubheader>
                Uh-oh. The data for this page is missing. Maybe you got here from an old link? If so
                please try again from a recent message.
              </SectionSubheader>
              <SectionSubheader>
                If you need urgent help with your shifts, please contact the office.
              </SectionSubheader>
            </Col>
          </Row>
        </Container>
      </StyledShiftListConfirmation>
    );
  }

  if (submittedAction === CONFIRMATION_ACTIONS.accept) {
    return (
      <StyledShiftListConfirmation>
        <Container>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <StyledConfirmationCardContainer>
                <SectionSubheader>
                  Thank you for confirming your shifts. We've let the office know you'll be there.
                  You can close this window.
                </SectionSubheader>
              </StyledConfirmationCardContainer>
            </Col>
          </Row>
        </Container>
      </StyledShiftListConfirmation>
    );
  }

  return (
    <StyledShiftListConfirmation>
      {error ? <ErrorContainer error={error} /> : null}
      {confirmationSet.length > 1 ? (
        <ConfirmationSetIntro
          confirmationSet={confirmationSet}
          handleConfirmAll={handleConfirmAll}
          loading={loading}
          summary={summary}
        />
      ) : null}
      <Container>
        <Row>
          <Col lg={{ offset: 3, span: 6 }}>
            {confirmationSet.map((c) => (
              <Confirmation confirmation={c} key={c.id} />
            ))}
          </Col>
        </Row>
      </Container>
    </StyledShiftListConfirmation>
  );
};

const ErrorContainer = ({ error }) => {};

const ConfirmationSetIntro = ({ confirmationSet, handleConfirmAll, loading, summary }) => {
  const name = confirmationSet[0].provider.nickname || confirmationSet[0].provider.first_name;
  return (
    <Container>
      <Row>
        <Col lg={{ offset: 3, span: 6 }}>
          <SectionSubheader>
            Hi {name}! {summary}
          </SectionSubheader>
          <ActionButton
            className="accept confirm-all"
            disabled={loading}
            onClick={handleConfirmAll}
            text={loading ? 'Submitting...' : 'Confirm My Shifts'}
          />
          <SectionSubheader>
            More details on your shifts are below. If you need to decline any shift, please press
            the "Cancel" button for that shift.
          </SectionSubheader>
        </Col>
      </Row>
    </Container>
  );
};

const Confirmation = ({ confirmation }) => {
  const { post } = useContext(HttpClientContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedAction, setSubmittedAction] = useState(null);

  const submit =
    ({ action, detail, id }) =>
    async () => {
      setLoading(true);
      const payload = detail ? { action, detail } : { action };
      try {
        await post({
          url: `confirmations/${id}/`,
          payload,
        });
        setSubmitted(true);
        setSubmittedAction(action);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

  if (!confirmation) {
    return null;
  }

  return (
    <StyledConfirmationCardContainer>
      <ShiftConfirmationCard
        confirmation={confirmation}
        error={error}
        loading={loading}
        showGreeting={false}
        submit={submit}
        submitted={submitted}
        submittedAction={submittedAction}
      />
    </StyledConfirmationCardContainer>
  );
};

const { spacing, screens } = styles;
const StyledShiftListConfirmation = styled.div`
  padding: ${spacing.quadSpace} 0 ${spacing.doubleSpace};

  h4 {
    margin-bottom: ${spacing.doubleSpace};
  }

  button.confirm-all {
    margin-bottom: ${spacing.quadSpace};
  }

  p,
  li {
    margin-bottom: ${spacing.singleSpace};
  }
`;

const StyledConfirmationCardContainer = styled.div`
  background-color: white;
  margin-bottom: ${spacing.doubleSpace};
  padding: ${spacing.doubleSpace};
  box-shadow:
    0px -2px 4px rgba(0, 0, 0, 0.1),
    -2px 0px 4px rgba(0, 0, 0, 0.1),
    2px 0px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.15);

  ${screens.md} {
    padding: ${spacing.doubleSpace};
  }
`;
