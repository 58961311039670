import { useState } from 'react';
import { Container } from 'react-bootstrap';

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { styles } from 'components';
import styled from 'styled-components';

import { RecommendationItem } from './RecommendationItem';

export const RecommendationList = ({ recommendations, setProviderIds }) => {
  const [items, setItems] = useState(recommendations);

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
      setProviderIds(items.map((item) => item.provider.id).join(','));
    }
  };
  const handleRemove = (rank) => {
    setItems((items) => items.filter((item) => item.rank !== rank));
    setProviderIds(items.map((item) => item.provider.id).join(','));
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <StyledRecommendationList>
          <Container fluid>
            {items.map((recommendation, index) => (
              <RecommendationItem
                id={recommendation}
                recommendation={recommendation}
                onRemove={handleRemove}
                key={recommendation.rank}
                rank={index + 1}
              />
            ))}
          </Container>
        </StyledRecommendationList>
      </SortableContext>
    </DndContext>
  );
};

const { sizing, weights, colors, spacing } = styles;

export const Span = styled.span`
  font-size: ${sizing.paragraphTextSize};
  font-weight: 400;
  margin-bottom: 0;

  &.small {
    font-size: 14px;
  }

  &.large {
    font-size: 20px;
  }

  &.bold {
    font-weight: ${weights.bold};
  }
`;

const StyledRecommendationList = styled.section`
  .header {
    line-height: 1;
    padding: 0 0;
    p {
      font-weight: 600;
    }
  }

  .table-row {
    p {
      font-size: 16px;
    }
    border-bottom: 1px solid ${colors.gray};
    margin-bottom: ${spacing.singleSpace};
  }
`;
