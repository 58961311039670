import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

import { styles } from 'components';
import styled from 'styled-components';

export const Footer = () => {
  return (
    <StyledFooter>
      <Container fluid>
        <Row>
          <Col xs={{ span: 10 }}>
            <NavLinks>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </NavLinks>
          </Col>
          <Col xs={{ span: 2 }}>
            <NavLinks>&copy; {new Date().getFullYear()}</NavLinks>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

const { colors } = styles;
const StyledFooter = styled.footer`
  background-color: ${colors.darkGray};
  color: white;
  width: 100%;
  padding: 30px 0;
`;

const NavLinks = styled.div`
  & > a {
    margin: 0 20px;
    color: white;
  }
`;
