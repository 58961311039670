import { Paragraph, SectionHeader } from "components";

export const IntakeHeader = ({ error }) => {
  return (
    <div className="header">
      <SectionHeader>Recipient Intake</SectionHeader>
      <Paragraph>Enter client details below to see caregiver matches</Paragraph>
      {error && <Paragraph className="small bold text-danger line-break">{error}</Paragraph>}
    </div>
  );
};
