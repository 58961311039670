import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { PageHeader, Paragraph, PrimaryButton, styles } from 'components';
import styled from 'styled-components';

const SignupBackground = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/signup-background.png`;

export const GetStarted = () => {
  return (
    <StyledGetStarted>
      <Container>
        <Row>
          <Col>
            <Paragraph>Sanctum</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8 }}>
            <PageHeader>Get started for free.</PageHeader>
          </Col>
          <Col md={{ span: 4 }}>
            <Paragraph>Risk-free 14 day trial, then flexible monthly plans.</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col className="button-container">
            <PrimaryButton link="/goku" text="Sign Up For Free" />
          </Col>
        </Row>
      </Container>
    </StyledGetStarted>
  );
};

const { colors, spacing } = styles;

const StyledGetStarted = styled.section`
  background-color: ${colors.blue};
  background-image: url(${SignupBackground});
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding: 120px 0;

  h2 {
    margin-bottom: ${spacing.singleSpace};
  }

  p {
    margin-bottom: ${spacing.doubleSpace};
  }

  .button-container {
    @media only screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  div.white-outline {
    @media only screen and (min-width: 768px) {
      margin-left: ${spacing.singleSpace};
    }
  }
`;
