import React from 'react';

import { formatDateTime, formatName } from 'utils';

import { ActionButton, Paragraph, styles } from 'components';
import styled from 'styled-components';

export const OFFER_ACTIONS = {
  accept: 'accept',
  decline: 'decline',
};

export const ShiftOfferDetailCard = ({
  offer,
  error,
  loading,
  showGreeting = true,
  submit,
  submittedAction,
}) => {
  if (!submittedAction) {
    return (
      <OfferDetails
        offer={offer}
        error={error}
        loading={loading}
        showGreeting={showGreeting}
        submit={submit}
      />
    );
  } else {
    return <ActionConfirmation submittedAction={submittedAction} />;
  }
};

const ActionConfirmation = ({ submittedAction }) => {
  if (submittedAction === OFFER_ACTIONS.accept) {
    return (
      <StyledActionCard>
        <Paragraph>
          Success! You have accepted this shift. Please call the office if you have any questions.
          You can close this window.
        </Paragraph>
      </StyledActionCard>
    );
  }
  if (submittedAction === OFFER_ACTIONS.decline) {
    return (
      <StyledActionCard>
        <Paragraph>
          You have declined this shift offer, thanks for your response. You can close this window.
        </Paragraph>
      </StyledActionCard>
    );
  }
};

const OfferDetails = ({ offer, error, loading, showGreeting, submit }) => {
  const { ErrorMessage } = styles;
  const { shift } = offer;
  const { recipient } = shift;
  const { month, day, hours, minutes, period } = formatDateTime(shift.start_date);
  const {
    month: endMonth,
    day: endDay,
    hours: endHours,
    minutes: endMinutes,
    period: endPeriod,
  } = formatDateTime(shift.end_date);

  const startDate = `${month} ${day}`;
  const startTime = `${hours}:${minutes} ${period}`;
  const endDate = `${endMonth} ${endDay}`;
  const endTime = `${endHours}:${endMinutes} ${endPeriod}`;

  console.log(offer);

  return (
    <StyledActionCard>
      <StyledHeader>
        <Paragraph>
          Hi {offer.provider.first_name}. There is a new shift available for a shift on {startDate}{' '}
          from {startTime} to {endTime} with {recipient.first_name} {recipient.last_name}. Do you
          want to take this shift?
        </Paragraph>
      </StyledHeader>
      <ShiftDetails
        endDate={endDate}
        endTime={endTime}
        startDate={startDate}
        startTime={startTime}
        recipient={recipient}
        offer={offer}
      />
      <OfferFlow offerId={offer.id} loading={loading} submit={submit} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledActionCard>
  );
};

const ShiftDetails = ({ recipient, offer, startDate, startTime, endDate, endTime }) => {
  return (
    <div>
      <Paragraph className="title">Offer Details</Paragraph>
      <ul>
        <li>
          <Paragraph>{formatName(recipient)}</Paragraph>
        </li>
        <li>
          <Paragraph>
            Start: {startDate} {startTime}
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            End: {endDate} {endTime}
          </Paragraph>
        </li>
        <li>
          <Paragraph>City: {recipient.address.city}</Paragraph>
        </li>
        {offer.provider.rates ? (
          <li>
            <Paragraph>{offer.provider.rates[0]}</Paragraph>
          </li>
        ) : null}
        {offer.incentive ? (
          <li>
            <OfferIncentive incentive={offer.incentive} />
          </li>
        ) : null}
      </ul>
    </div>
  );
};

const OfferIncentive = ({ incentive }) => {
  const { value, unit } = incentive;
  return (
    <Paragraph>
      Bonus: {unit === 'cash' ? `$${value}` : `${value} ${unit}`}. This a one-time bonus.
    </Paragraph>
  );
};

const OfferFlow = ({ offerId, loading, submit }) => {
  return (
    <StyledOfferFlow>
      <ActionButton
        className="accept"
        disabled={loading}
        onClick={submit({ action: OFFER_ACTIONS.accept, id: offerId })}
        text={loading ? 'Submitting...' : 'Accept Shift'}
      />
      <ActionButton
        className="decline"
        disabled={loading}
        onClick={submit({ action: OFFER_ACTIONS.decline, id: offerId })}
        text={loading ? 'Submitting...' : 'Decline Shift'}
      />
    </StyledOfferFlow>
  );
};

const { spacing, colors } = styles;
const StyledActionCard = styled.div`
  padding: ${spacing.doubleSpace};

  ul {
    margin-bottom: ${spacing.tripleSpace};
    li p {
      font-weight: 500;
    }
  }

  p.title {
    font-weight: 700;
  }

  button {
    display: block;
    width: 100%;
    margin-bottom: ${spacing.singleSpace};
    padding: ${spacing.singleSpace};

    &.accept {
      color: white;
      background-color: ${colors.green};
    }
    &.decline {
      color: black;
      background-color: white;
      border: 1px solid ${colors.mediumGray};
    }
  }
`;

const StyledHeader = styled.div`
  margin-bottom: ${spacing.doubleSpace};
`;

const StyledOfferFlow = styled.div`
  margin-bottom: ${spacing.quadSpace};
`;
