export const colors = {
  almostBlack: 'rgb(10,10,10)',
  blue: 'rgb(23,67,173)',
  lightBlue: 'rgb(107,194,249)',
  lightBlueOpaque: 'rgb(0,93,255,0.22)',
  logoBlue: 'rgb(0,112,204)',
  blueOpaque: 'rgba(34,107,193,0.3)',
  darkBlue: '#3617AD',
  brightGreen: '#33b249',
  darkGray: 'rgba(40,40,40,0.3)',
  gray: 'rgba(100,100,100)',
  lightGray: 'rgba(242,244,245)',
  lightRed: 'rgba(230,53,87,0.5)',
  mediumGray: 'rgba(150,150,150)',
  placeholderGray: 'rgb(73,80,87)',
  green: 'rgb(58,148,93)',
  greenOpaque: 'rgba(0,167,92,0.2)',
  orange: 'rgb(238,118,0)',
  red: 'rgb(230,53,87)',
  redOpaque: 'rgb(255,10,0,0.15)',
  teal: 'rgb(168,218,220)',
  tealOpaque: 'rgba(168,218,220,0.8)',
  yellowOpaque: 'rgba(238,210,2,0.5)',
  whiteOpaque: 'rgba(255,255,255,0.8)',
};

export const spacing = {
  microSpace: '4px',
  halfSpace: '8px',
  singleSpace: '15px',
  doubleSpace: '30px',
  tripleSpace: '45px',
  quadSpace: '60px',
  doubleQuad: '120px',
  formInputPadding: '25px 20px',
};

export const screens = {
  md: '@media only screen and (min-width: 768px)',
  lg: '@media only screen and (min-width: 992px)',
  xl: '@media only screen and (min-width: 1200px)',
};

export const sizing = {
  labelTextSize: '14px',
  buttonTextSize: '16px',
  buttonTextKerning: '0.8px',
  inputTextSize: '16px',
  listTextSize: '16px',
  paragraphTextSize: '18px',
  sectionSubheaderTextSize: '22px',
  subheaderTextSize: '28px',
};

export const weights = {
  button: 700,
  bold: 600,
  semiBold: 400,
};
