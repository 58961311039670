import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { SectionSubheader, styles } from 'components';
import styled from 'styled-components';

export const Header = ({ showCalloutDashboard, showShiftList, visibleScreen }) => {
  return (
    <StyledHeader>
      <Row>
        <Col>
          <StyledButtonContainer>
            <button
              className={visibleScreen === 'shiftList' ? 'selected' : ''}
              onClick={showShiftList}
            >
              <SectionSubheader>All Shifts</SectionSubheader>
            </button>
            <button
              className={visibleScreen === 'calloutDashboard' ? 'selected' : ''}
              onClick={showCalloutDashboard}
            >
              <SectionSubheader>Callouts</SectionSubheader>
            </button>
          </StyledButtonContainer>
        </Col>
      </Row>
    </StyledHeader>
  );
};

const { spacing } = styles;
const StyledHeader = styled.section`
  margin: ${spacing.singleSpace} 0 ${spacing.doubleSpace};

  button {
    background: none;
    padding: ${spacing.singleSpace};
    border: none;
    margin-right: 10px;

    &:hover,
    &.selected {
      text-decoration: underline;
    }

    h4 {
      font-weight: 700;
    }
  }
`;

const StyledButtonContainer = styled.div`
  padding-left: 2%;
`;
