import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  AuthContext,
  BasicLink,
  ErrorMessage,
  HttpClientContext,
  Input,
  PrimaryButton,
  SectionSubheader,
  styles,
} from 'components';
import styled from 'styled-components';

export const Login = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [username, setUsername] = useState(searchParams.get('username') || '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { post } = useContext(HttpClientContext);
  const { loggedIn, login } = useContext(AuthContext);

  const loginToken = searchParams.get('token');
  const expiredSession = searchParams.get('expired_session');
  const redirect = decodeURIComponent(searchParams.get('redirect') || '/shift-dashboard');

  useEffect(() => {
    if (expiredSession) {
      setError('Your session has expired. Please log in again.');
      navigate('/login');
    }
  }, [expiredSession, navigate]);

  useEffect(() => {
    if (loggedIn) {
      navigate(redirect);
    }
  }, [redirect, loggedIn, navigate]);

  useEffect(() => {
    if (!loginToken) return;

    const logInWithToken = async () => {
      setLoading(true);
      try {
        const res = await post({
          url: 'users/link/login',
          payload: { token: loginToken },
        });
        if (res.status !== 200) {
          throw Error('Invalid login token. Please try again.');
        }
        const user = res.data.user;
        user['token'] = res.data.token;
        login(user);
        navigate(redirect);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    logInWithToken();
  }, [login, loginToken, navigate, post, redirect]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      console.log('posting...');
      const res = await post({
        url: 'users/auth/login',
        payload: {
          username,
          password,
        },
      });
      if (res.status !== 200) {
        if (res.response.status === 401) throw Error('Invalid credentials. Please try again');
        throw Error('System error. Please contact support');
      }
      const user = res.data.user;
      user['token'] = res.data.token;
      login(user);
      navigate(redirect);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <StyledLogin>
      <Container>
        <Row>
          <Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
            <StyledLoginForm onSubmit={handleSubmit}>
              <div style={{ marginBottom: styles.spacing.singleSpace }}>
                <SectionSubheader className="large">Log in to Sanctum</SectionSubheader>
              </div>
              <Input
                autoFocus
                onChange={(e) => setUsername(e.target.value)}
                placeholder="username@example.com"
                type="username"
                autoComplete="username"
                value={username}
              />
              <Input
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter a password"
                type="password"
                autoComplete="current-password"
                value={password}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <StyledButton>
                <PrimaryButton
                  text={loading ? 'Logging in...' : 'Log In'}
                  disabled={!username || !password || loading}
                  onClick={handleSubmit}
                  role="submit"
                />
              </StyledButton>
              <BasicLink onClick={() => navigate(`/login/link`)}>Send me a login link</BasicLink>
            </StyledLoginForm>
          </Col>
        </Row>
      </Container>
    </StyledLogin>
  );
};

const { colors, spacing } = styles;
const StyledLogin = styled.div`
  flex: 1;
  background-color: ${colors.blue};
  padding: ${spacing.quadSpace} 0;
`;

const StyledLoginForm = styled.form`
  background-color: white;
  border-radius: ${spacing.halfSpace};
  padding: ${spacing.doubleSpace};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.div`
  width: 100%;

  button {
    width: 100%;
  }
`;
