import { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { capitalCase } from 'change-case';
import { snakeCase } from 'change-case/keys';
import { SectionSubheader, styles } from 'components';
import styled from 'styled-components';

export const ProfileIntake = ({ setMatchParams, setMissingInputs }) => {
  const [profile, setProfile] = useState({
    firstName: null,
    lastName: null,
    streetAddress: null,
    city: null,
    postalCode: null,
  });

  const checkMissingInputs = useCallback(() => {
    const missingInputs = [];
    Object.keys(profile).forEach((key) => {
      if (profile[key] === null) {
        missingInputs.push(`${capitalCase(key)}`);
      }
    });

    return setMissingInputs((prev) => {
      return { ...prev, profile: missingInputs };
    });
  }, [profile, setMissingInputs]);

  const parseProfileParams = useCallback(() => {
    const recipient = snakeCase({
      firstName: profile.firstName,
      lastName: profile.lastName,
      address: snakeCase({
        streetAddress: profile.streetAddress,
        city: profile.city,
        postalCode: profile.postalCode,
      }),
      attributesRequired: [],
    });

    setMatchParams((prev) => {
      return { ...prev, recipient };
    });
  }, [profile, setMatchParams]);

  const updateProfile = useCallback(
    (e) => {
      const { id, value } = e.target;
      setProfile((prev) => {
        return { ...prev, [id]: capitalCase(value.trim()) };
      });

      checkMissingInputs();
      parseProfileParams();
    },

    [setProfile, checkMissingInputs, parseProfileParams],
  );

  useEffect(() => {
    checkMissingInputs();
  }, [checkMissingInputs]);

  return (
    <StyledProfileIntake>
      <SectionSubheader className="bold my-3">Profile</SectionSubheader>
      <Row className="my-4">
        <Form.Group as={Col} className="col-sm-6" controlId="firstName">
          <StyledFormControl
            type="text"
            placeholder="First Name"
            required
            onChange={updateProfile}
          />
        </Form.Group>
        <Form.Group as={Col} className="col-sm-6" controlId="lastName">
          <StyledFormControl
            type="text"
            placeholder="Last Name"
            required
            onChange={updateProfile}
          />
        </Form.Group>
      </Row>
      <Form.Group className="my-4" controlId="streetAddress">
        <StyledFormControl placeholder="Street Address" required onChange={updateProfile} />
      </Form.Group>
      <Form.Group className="my-4" controlId="city">
        <StyledFormControl placeholder="City" required onChange={updateProfile} />
      </Form.Group>
      <Form.Group className="my-4" controlId="postalCode">
        <StyledFormControl placeholder="Zip Code" required onChange={updateProfile} />
      </Form.Group>
    </StyledProfileIntake>
  );
};
const { colors, spacing, sizing } = styles;

const StyledProfileIntake = styled.div`
  margin-bottom: ${spacing.quadSpace};
`;

const StyledFormControl = styled(Form.Control)`
  padding: ${spacing.formInputPadding};
  border: 1px solid ${colors.mediumGray};
  font-size: ${sizing.inputTextSize};
  border-radius: 8px;
`;
