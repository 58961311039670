import dayjs from 'dayjs';

export const formatDateTime = (dateTimeString) => {
  // Expects dateTimeString in format 2024-04-11T16:00:00Z
  const date = new Date(dateTimeString);

  const options = { month: 'long' };
  const month = new Intl.DateTimeFormat('en-US', options).format(date);
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure 2 digits

  // Convert to 12-hour format
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  return {
    month,
    day,
    hours,
    minutes,
    period,
  };
};

export const isBefore = (date1, date2) => {
  return date1.getTime() < date2.getTime();
};

export const formatStandardTime = (dateTime, meridiem = true) => {
  if (meridiem) {
    return dayjs(dateTime).format('hh:mm A');
  } else {
    return dayjs(dateTime).format('hh:mm');
  }
};

export const formatMilitaryTime = (dateTime) => {
  return dayjs(dateTime).format('HH:mm');
};
